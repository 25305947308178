import React, { useCallback, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { downloadFile } from '../utils/helperFunctions';
import Upload from '../assets/upload';
import Save from '../assets/Save';
import Download from '../assets/download';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrganizationType = {
  Municipality: 'MUNICIPALITY',
  HOA: 'HOA',
  School: 'SCHOOL',
  'Private Lot': 'PRIVATE_LOT',
};
const CREATE_MUNICIPALITY_MUTATION = gql`
  mutation CreateMunicipality($input: CreateMunicipalityInput!) {
    createMunicipality(createMunicipalityInput: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

const CreateMunicipalityForm = () => {
  const [createMunicipality] = useMutation(CREATE_MUNICIPALITY_MUTATION);
  const fileInputRefs = useRef({
    logo: null,
    users: null,
    parkingPermits: null,
    // parkingZones: null,
    customerTypes: null,
    violations: null,
  });

  const [fileNames, setFileNames] = useState({
    logo: null,
    users: null,
    parkingPermits: null,
    // parkingZones: null,
    customerTypes: null,
    violations: null,
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      logo: null,
      users: null,
      parkingPermits: null,
      customerTypes: null,
      // parkingZones: null,
      violations: null,
      citationLayout: {
        ticketTitle: '',
        bottomText: '',
        citationNumber: true,
        description: true,
        location: true,
        violation: true,
        vehicle: true,
        dateTime: true,
        licensePlate: true,
        image: true,
        poweredByText: false,
      },
      enableParkingPermits: true,
      enableCitations: true,
      allowPermitChanges: true,
      organizationType: 'Municipality',
      maxPermit: '',
      citationContestEmail: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      logo: Yup.mixed().required('Required'),
      users: Yup.mixed().required('Required'),
      parkingPermits: Yup.lazy(value =>
        formik?.values?.enableParkingPermits
          ? Yup.mixed().required('Required')
          : Yup.mixed().notRequired()
      ),
      customerTypes: Yup.lazy(value =>
        formik?.values?.enableParkingPermits
          ? Yup.mixed().required('Required')
          : Yup.mixed().notRequired()
      ),
      violations: Yup.lazy(value =>
        formik?.values?.enableCitations
          ? Yup.mixed().required('Required')
          : Yup.mixed().notRequired()
      ),
      citationLayout: Yup.lazy(value =>
        formik?.values?.enableCitations
          ? Yup.object({
              ticketTitle: Yup.string().required('Required'),
              bottomText: Yup.string().required('Required'),
            })
          : Yup.object({
              ticketTitle: Yup.string().notRequired(),
              bottomText: Yup.string().notRequired(),
            })
      ),
      organizationType: Yup.string().required(
        'Please select an organization type'
      ),
      maxPermit: Yup.number().typeError('Must be a number').notRequired(),
      citationContestEmail: Yup.lazy(value =>
        formik?.values?.enableCitations
          ? Yup.string().email('Invalid email address').required('Required')
          : Yup.string().email('Invalid email address').notRequired()
      ),

      // parkingZones: Yup.lazy(value =>
      //   formik?.values?.citationLayout?.enableZones
      //     ? Yup.mixed().required('Required')
      //     : Yup.mixed().notRequired()
      // ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const input = {
          name: values.name,
          logo: values.logo,
          users: values.users,
          parkingPermits: values.parkingPermits,
          customerTypes: values.customerTypes,
          allowPermitChanges: values.allowPermitChanges,
          maxPermit: values.maxPermit,
          // parkingZones: values.parkingZones,
          violations: values.violations,
          citationContestEmail: values.citationContestEmail,
          enableParkingPermits: values.enableParkingPermits,
          enableCitations: values.enableCitations,
          organizationType: OrganizationType[values.organizationType],
          citationLayout: {
            ticketTitle: values.citationLayout.ticketTitle,
            bottomText: values.citationLayout.bottomText,
            citationNumber: values.citationLayout.citationNumber,
            description: values.citationLayout.description,
            location: values.citationLayout.location,
            violation: values.citationLayout.violation,
            vehicle: values.citationLayout.vehicle,
            dateTime: values.citationLayout.dateTime,
            licensePlate: values.citationLayout.licensePlate,
            image: values.citationLayout.image,
            poweredByText: values.citationLayout.poweredByText,
            // enablePermits: values.citationLayout.enablePermits,
            // enableZones: values.citationLayout.enableZones,
          },
        };

        const { data } = await createMunicipality({
          variables: {
            input: {
              ...input,
            },
          },
        });

        console.log(data);
        toast.success('Municipality created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });

        // Reset file inputs manually
        Object.keys(fileInputRefs.current).forEach(key => {
          if (fileInputRefs.current[key]) {
            fileInputRefs.current[key].value = null;
          }
        });

        setFileNames({
          logo: null,
          users: null,
          parkingPermits: null,
          customerTypes: null,
          // parkingZones: null,
          violations: null,
        });
        formik.resetForm();
      } catch (error) {
        toast.error('Error while creating municipality', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
        console.error('Error creating municipality:', error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFileChange = useCallback(
    (event, field) => {
      const file = event.currentTarget.files[0];
      formik.setFieldValue(field, file);
      setFileNames(prevFileNames => ({
        ...prevFileNames,
        [field]: file ? file.name : '',
      }));
    },
    [formik]
  );

  const handleFileInputClick = field => {
    fileInputRefs.current[field]?.click();
  };

  const renderFileInput = (field, label) => {
    const filePath = {
      users: '/users.csv',
      violations: '/violations.csv',
      parkingPermits: '/parkingPermits.csv',
      customerTypes: '/customerTypes.csv',
      // parkingZones: '/parkingZones.csv',
    };
    return (
      <div className="flex flex-col">
        <label className="text-black text-opacity-50" htmlFor={field}>
          {label
            ? label
            : field.charAt(0).toUpperCase() +
              field.slice(1).replace(/([A-Z])/g, ' $1')}
        </label>
        <input
          id={field}
          name={field}
          type="file"
          accept={'.csv'}
          ref={el => (fileInputRefs.current[field] = el)}
          onChange={event => handleFileChange(event, field)}
          onBlur={formik.handleBlur}
          className="hidden"
        />
        <div className="flex flex-row">
          <button type="button" onClick={() => handleFileInputClick(field)}>
            <div className="flex flex-row justify-center items-center">
              <div class="flex flex-col justify-start items-start myScreen:flex-row myScreen:justify-between myScreen:items-center">
                <div
                  class={`flex justify-start items-center bg-[#FDFDFD] gap-2  py-1 px-3   rounded-md  text-black text-sm border ${
                    formik.touched[field] &&
                    formik.errors[field] &&
                    'border border-red-500'
                  }`}
                >
                  <Upload />
                  Upload
                </div>
              </div>
              <p className="max-w-[120px] text-sm text-black mt-1 mx-2 text-left overflow-hidden whitespace-nowrap text-ellipsis">
                {fileNames[field] ?? 'no file selected'}
              </p>
            </div>
          </button>
          <DownloadTemplateButton filePath={filePath[field]} />{' '}
        </div>
        <div className="h-5">
          {formik.touched[field] && formik.errors[field] ? (
            <div className="text-red-500 text-sm">{formik.errors[field]}</div>
          ) : null}
        </div>
      </div>
    );
  };

  const DownloadTemplateButton = ({ filePath }) => (
    <button
      type="button"
      onClick={() => downloadFile(filePath)}
      className="text-sm text-black text-nowrap"
    >
      <div class="inline-flex justify-start items-center bg-[#FDFDFD] gap-2  py-1 px-3 rounded-md text-black text-sm">
        <Download />
        Download Template
      </div>
    </button>
  );

  const Checkbox = ({ field = '' }) => (
    <div key={field}>
      <label className="text-black text-opacity-50">
        <input
          name={`citationLayout.${field}`}
          type="checkbox"
          onChange={formik.handleChange}
          checked={formik.values.citationLayout[field]}
          className="mr-2"
          style={{
            accentColor: formik.values.citationLayout[field]
              ? '#F75474'
              : '#007bff',
          }}
        />
        {field.charAt(0).toUpperCase() +
          field.slice(1).replace(/([A-Z])/g, ' $1')}
      </label>
    </div>
  );

  const PermitCheckbox = ({ field = '', label }) => (
    <div key={field}>
      <label>
        <input
          name={`${field}`}
          type="checkbox"
          onChange={formik.handleChange}
          checked={formik.values[field]}
          className="mr-2"
          style={{
            accentColor: formik.values[field] ? '#F75474' : '#007bff',
          }}
        />
        {label
          ? label
          : field.charAt(0).toUpperCase() +
            field.slice(1).replace(/([A-Z])/g, ' $1')}
      </label>
    </div>
  );
  return (
    <div className="flex flex-col justify-start items-start lg:justify-center lg:items-center px-20 overflow-auto">
      <div className="flex flex-row justify-center items-center mb-6">
        <img
          src={require('../assets/enforceLogo.png')}
          className="w-10 h-10 object-contain"
          alt="Enforce logo"
          style={{ width: '40px', height: '40px' }}
        />
        <p className="text-[34px] font-bold ml-5">Super Admin</p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className=" bg-[#EDEDED] p-6 flex flex-col justify-center items-center"
        encType={'multipart/form-data'}
      >
        <div className="flex gap-6 lg:gap-0 flex-col justify-center items-start lg:flex-row  lg:pb-[10px] lg:pt-0 border-b border-b-black border-opacity-10">
          {/* div1 */}
          <div className="flex-1 lg:pr-9">
            <div className="flex flex-col justify-start items-start gap-[11px] w-full">
              <label className="font-bold" htmlFor="name">
                Organization
              </label>
              <div className="w-full">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Organization Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className={`p-4 block w-full border ring-0 focus-visible:ring-0 rounded-md ${
                    formik.touched.name &&
                    formik.errors.name &&
                    'border-red-500'
                  }`}
                />
                <div className="h-5">
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <label
                className="mb-3 text-black text-opacity-50"
                htmlFor="organizationType"
              >
                Organization Type
              </label>
              <div className="flex gap-4">
                {['Municipality', 'School', 'HOA', 'Private lot'].map(type => (
                  <label key={type} className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="organizationType"
                      value={type}
                      checked={formik.values.organizationType === type}
                      onChange={formik.handleChange}
                      className="form-radio"
                      style={{
                        accentColor: '#F75474',
                      }}
                    />
                    <span className="text-sm">{type}</span>
                  </label>
                ))}
              </div>
              <div className="h-5">
                {formik.touched.organizationType &&
                formik.errors.organizationType ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.organizationType}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <label className="text-black text-opacity-50" htmlFor="logo">
                Logo (Image)
              </label>
              <input
                id="logo"
                name="logo"
                type="file"
                accept="image/*"
                ref={el => (fileInputRefs.current['logo'] = el)}
                onChange={event => handleFileChange(event, 'logo')}
                onBlur={formik.handleBlur}
                className="hidden"
              />
              <div className="flex justify-start items-start gap-2 pt-1">
                <button
                  type="button"
                  onClick={() => handleFileInputClick('logo')}
                  className=" "
                >
                  <div
                    className={`flex justify-center items-center bg-[#FDFDFD] gap-2 py-1 px-3 rounded-md text-black text-sm border ${
                      formik.touched.logo &&
                      formik.errors.logo &&
                      'border border-red-500'
                    }`}
                  >
                    <Upload />
                    Upload
                  </div>
                </button>
                <p className="text-black mt-1 text-nowrap text-sm text-left">
                  {fileNames.logo ?? 'no file selected'}
                </p>
              </div>
              <div className="h-5">
                {formik.touched.logo && formik.errors.logo ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.logo}
                  </div>
                ) : null}
              </div>
            </div>
            {renderFileInput('users')}
          </div>

          {/* div2 */}
          <div className="flex-1 h-full lg:px-9 lg:border-x lg:border-x-black  lg:border-opacity-10 ">
            <div class="flex flex-col justify-start items-start ">
              <PermitCheckbox field={'enableParkingPermits'} />
            </div>
            <div
              className={`pt-4 ${
                !formik.values.enableParkingPermits
                  ? 'opacity-50 pointer-events-none'
                  : ''
              }`}
            >
              {renderFileInput('parkingPermits', 'Parking Permit Zones')}
              <div class="flex flex-col justify-start items-start gap-4">
                {renderFileInput('customerTypes')}
              </div>
              <div className="mb-4">
                <PermitCheckbox
                  field={'allowPermitChanges'}
                  label={'Allow permit changes (digital permits)'}
                />
              </div>
              <div>
                <label className="text-black text-opacity-50">
                  Limit # of Permits (optional)
                </label>
                <input
                  id="maxPermit"
                  name="maxPermit"
                  type="number"
                  placeholder="ex. 200"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.maxPermit}
                  className={`p-4 block  border ring-0 focus-visible:ring-0 rounded-md ${
                    formik.touched.maxPermit &&
                    formik.errors.maxPermit &&
                    'border-red-500'
                  }`}
                />
                <div className="h-5">
                  {formik.touched.maxPermit && formik.errors.maxPermit ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.maxPermit}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div class="flex flex-col justify-start items-start gap-4 pb-[40px] pt-[24px] ">
              <label className="text-sm font-bold" htmlFor="ticketTitle">
                Parking Zones
              </label>
              <Checkbox field={'enableZones'} />
              {renderFileInput('parkingZones')}
            </div> */}
            </div>
          </div>

          {/* div3 */}
          <div className="flex-1 lg:pl-9 ">
            <div className="pb-4">
              <PermitCheckbox field={'enableCitations'} />
            </div>
            <div
              className={`${
                !formik.values.enableCitations
                  ? 'opacity-50 pointer-events-none'
                  : ''
              }`}
            >
              <div className="flex flex-col justify-start items-start min-w-[362px]">
                {renderFileInput('violations', 'List of Violations')}
                <div className="w-full">
                  <input
                    id="citationContestEmail"
                    name="citationContestEmail"
                    type="email"
                    placeholder="Email for citation disputes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.citationContestEmail}
                    className={`p-4 block w-full border ring-0 focus-visible:ring-0 rounded-md ${
                      formik.touched.citationContestEmail &&
                      formik.errors.citationContestEmail &&
                      'border-red-500'
                    }`}
                  />
                  <div className="h-5">
                    {formik.touched.citationContestEmail &&
                    formik.errors.citationContestEmail ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.citationContestEmail}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-4 h-[1px] bg-black w-full bg-opacity-10" />
                <label className="font-bold mb-4" htmlFor="ticketTitle">
                  Citation Layout
                </label>
                <input
                  id="ticketTitle"
                  name="citationLayout.ticketTitle"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Ticket Title"
                  value={formik.values.citationLayout.ticketTitle}
                  className={`p-4 block w-full border ring-0 focus-visible:ring-0 rounded-md ${
                    formik.touched.citationLayout?.ticketTitle &&
                    formik.errors.citationLayout?.ticketTitle &&
                    'border-red-500'
                  }`}
                />
                <div className="h-5">
                  {formik.touched.citationLayout?.ticketTitle &&
                  formik.errors.citationLayout?.ticketTitle ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.citationLayout.ticketTitle}
                    </div>
                  ) : null}
                </div>
              </div>

              <label htmlFor="ticketTitle">
                Items to Display on the Ticket
              </label>
              <div className="flex md:flex-col lg:flex-row lg:flex-wrap mt-4 mb-6">
                {[
                  'citationNumber',
                  'description',
                  'location',
                  'violation',
                  'vehicle',
                  'dateTime',
                  'licensePlate',
                  'image',
                  'poweredByText',
                ].map((field, index) => (
                  <div className="w-1/2 p-1" key={index}>
                    <Checkbox field={field} />
                  </div>
                ))}
              </div>

              <textarea
                id="bottomText"
                name="citationLayout.bottomText"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Text to be printed at the bottom of each ticket from this municipality"
                value={formik.values.citationLayout.bottomText}
                className={`p-4 block w-full border ring-0 focus-visible:ring-0 rounded-md outline-none ${
                  formik.touched.citationLayout?.bottomText &&
                  formik.errors.citationLayout?.bottomText &&
                  'border border-red-500'
                }`}
              />
              <div className="h-5">
                {formik.touched.citationLayout?.bottomText &&
                formik.errors.citationLayout?.bottomText ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.citationLayout.bottomText}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={
            formik.isSubmitting ||
            (!formik.values.enableParkingPermits &&
              !formik.values.enableCitations)
          }
          className={`flex justify-center items-center pt-[24px] ${
            formik.isSubmitting ||
            (!formik.values.enableParkingPermits &&
              !formik.values.enableCitations)
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
        >
          <div className="flex justify-center items-center px-9 py-4 rounded-full bg-[#F75474] text-white font-bold text-base gap-3">
            <Save />
            {formik.isSubmitting ? 'Creating...' : 'Create Municipality'}
          </div>
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateMunicipalityForm;
